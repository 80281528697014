import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Footer from "../components/footer"

import BrandsLanding from "../components/brands_landing"
import TestimonialsAgencies from "../components/testimonials_agencies"
import AgenciesFaq from "../components/for_agencies_faq"

import flowfuel from "../images/flowfuel.mp4"
import wave from "../images/wave.svg"
import propdata from "../images/logos/propdata.svg"
import flowFuelLogo from "../images/logos/FlowFuel_Logo.png"
import flowBrandLogo from "../images/logos/FlowBrand_Logo.png"
import flowRecruitLogo from "../images/logos/FlowRecruit_Logo.png"
import AgencyAds from "../images/agency_ads.png"
import SuburbRelationship from "../images/suburb_relationship.png"
import AgencyPage from "../images/agency_page.png"
import LeadsDiagram from "../images/leads_diagram.png"
import LeadAnalyticsDashboard from "../images/lead_analytics_dashboard.png"
import CasestudySeeff from "../images/casestudy_seeff.png"

const ForAgencies = () => (
  <Layout>
    <SEO
      title="Your agency deserves better. Welcome to the proptech revolution."
      description="Flow automatically creates ads from your listings and agent
      profile and targets them at the right people on Facebook and
      Instagram to build your brand and generate leads straight to
      your agents mobile."
      image={LeadsDiagram}
    />

    <section className="w-full md:px-10 relative">
      <div className="container mx-auto py-5">
        <header className="flex flex-col-reverse md:flex-row w-full top-0">
          <div className="flex w-full md:w-1/2 mt-12 md:mt-0">
            <div className="self-center text-center md:text-left">
              <h1 className="text-3xl md:text-5xl text-gray-700 font-bold leading-tight tracking-tight md:mx-0 animated fadeIn">
                Your <span className="wrap">agency</span> deserves better.
                Welcome to the <span className="wrap">proptech</span>{" "}
                revolution.
              </h1>
              <p className="inline-block text-md md:text-xl font-light text-gray-400 max-w-md mx-auto mt-3 px-10 md:px-0">
                Flow automatically creates ads from your listings and agent
                profiles and targets them at the right people on Facebook and
                Instagram to build your brand and generate leads straight to
                your agents’ mobiles.
              </p>

              <Link
                to="/book-demo"
                className="inline-block bg-orange-500 text-white rounded-lg p-3 cursor-pointer mt-5"
              >
                Book a demo <i className="fas fa-arrow-right ml-2"></i>
              </Link>
            </div>
          </div>

          <div className="w-full md:w-1/2 mx-auto lottieWidth">
            <video width="500" height="500" playsInline autoPlay loop muted>
              <source src={flowfuel} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </header>
      </div>
    </section>

    <img className="w-full" src={wave} alt="" />

    <section className="w-full mt-10 md:mb-32 px-10 md:px-0">
      <h3 className="text-xl text-gray-700 text-center mt-4">
        Trusted by top agencies using Flow to grow their business.
      </h3>

      <div className="mt-10 md:w-3/5 mx-auto">
        <BrandsLanding />
      </div>
    </section>

    <section className="container mx-auto px-10 md:px-0">
      <div className="w-full text-center">
        <h2 className="text-2xl md:text-4xl mx-auto max-w-xl font-bold text-gray-700 leading-none">
          A proptech marketing platform to grow your agency business
        </h2>
        <p className="inline-block text-md md:text-xl font-light text-gray-400 max-w-2xl mx-auto mt-3">
          Reach millions of buyers, renters, sellers and agents right where they
          are. Flow automatically creates ads from your listings and brand
          profiles and targets them to the right people, at the right time
          helping your build your brand, sending leads straight to your agents'
          phones.
        </p>
      </div>
    </section>

    <section className="container mx-auto px-10 md:px-0 py-10">
      <div className="flex grid grid-flow-row md:grid-flow-col md:grid-cols-3 gap-5">
        <div className="bg-gray-100 text-center rounded-xl p-4">
          <img className="h-16 mx-auto mb-4" src={flowFuelLogo} alt="" />
          <p className="font-light text-gray-400">
            Automated listing ads to generate buyer, seller and tenant leads
          </p>
        </div>
        <div className="bg-gray-100 text-center rounded-xl p-4">
          <img className="h-16 mx-auto mb-4" src={flowBrandLogo} alt="" />
          <p className="font-light text-gray-400">
            Automated digital presence ads to keep you Top-Of-Mind in your
            suburbs
          </p>
        </div>
        <div className="bg-gray-100 text-center rounded-xl p-4">
          <img className="h-16 mx-auto mb-4" src={flowRecruitLogo} alt="" />
          <p className="font-light text-gray-400">
            Automated recruitment ads to showcase your business to recruit
            qualified agents
          </p>
        </div>
      </div>
    </section>

    <section className="container mx-auto py-10 md:py-16">
      <div className="w-full overflow-hidden bg-gray-100 rounded-xl">
        <TestimonialsAgencies />
      </div>
    </section>

    <section className="container mx-auto px-10 md:px-0">
      <div className="w-full text-center">
        <h2 className="text-2xl md:text-4xl mx-auto font-bold text-gray-700">
          See how Flow can <br /> <span className="wrap">work for you</span>
        </h2>
      </div>
    </section>

    <section className="mt-12 md:mt-0 py-10 md:py-16 px-5 mx-5 md:mx-10 rounded-xl">
      <div className="container mx-auto flex flex-col-reverse md:flex-row">
        <div className="w-full md:w-1/2 text-center md:text-left">
          <h2 className="text-2xl md:text-3xl text-center md:text-left font-bold text-gray-700 leading-none mt-4 md:mt-3">
            Automated property ads, shown everywhere
          </h2>
          <p className="text-md md:text-xl text-center md:text-left font-light text-gray-400 max-w-md mt-4">
            Flow automatically creates 16+ ad formats using your listings and
            agency profile. In the time you’re done reading this they are placed
            on Facebook, Instagram and websites across the web.
          </p>

          <div className="w-full flex text-left max-w-sm mt-12">
            <div className="flex inline-block w-5 h-5 rounded-full border-2 text-orange-500 border-orange-500 text-white flex-shrink-0 text-center mr-4">
              <i className="w-full self-center text-xxs fas fa-check"></i>
            </div>
            <div>
              <p className="font-medium text-gray-700 mb-2">
                Manage listings or connect your property feed from Prop Data and
                other leading partners.
              </p>
              <div className="flex">
                <div className="h-12 bg-gray-200 p-2 px-4 rounded-lg inline-block">
                  <img className="h-full" src={propdata} alt="" />
                </div>
                <div className="h-12 p-2 px-2 inline-block pt-3">+</div>
                <div className="h-12 bg-gray-200 p-2 px-4 rounded-lg inline-block text-sm leading-tight text-center">More Feed<br /> Integrations</div>
              </div>
            </div>
          </div>

          <div className="w-full flex text-left max-w-sm mt-3">
            <div className="flex inline-block w-5 h-5 rounded-full border-2 text-orange-500 border-orange-500 text-white flex-shrink-0 text-center mr-4">
              <i className="w-full self-center text-xxs fas fa-check"></i>
            </div>
            <div>
              <p className="font-medium text-gray-700 ">
                Features listings by sole mandate, specific agents, price range,
                area and more.
              </p>
            </div>
          </div>

          <div className="w-full flex text-left max-w-sm mt-3">
            <div className="flex inline-block w-5 h-5 rounded-full border-2 text-orange-500 border-orange-500 text-white flex-shrink-0 text-center mr-4">
              <i className="w-full self-center text-xxs fas fa-check"></i>
            </div>
            <div>
              <p className="font-medium text-gray-700 ">
                Choose templates to promote sold stock, agent profiles, your
                testimonials and awards.
              </p>
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/2">
          <img
            className="mx-auto w-full mb-10 md:mb-0 md:pl-20"
            src={AgencyAds}
            alt=""
          />
        </div>
      </div>
    </section>

    <section className="bg-gray-100 mt-12 md:mt-12 py-10 md:py-16 px-5 mx-5 md:mx-10 rounded-xl">
      <div className="container mx-auto flex flex-col-reverse md:flex-row-reverse">
        <div className="w-full md:w-1/2 text-center md:text-left">
          <h2 className="text-2xl md:text-3xl text-center md:text-left font-bold text-gray-700 leading-none mt-4 md:mt-3">
            Attract & build deeper relationships in your suburb
          </h2>
          <p className="text-md md:text-xl text-center md:text-left font-light text-gray-400 max-w-md mt-4">
            Ads are automatically targeted to your suburbs to build your brand,
            making sure sellers and buyers think of you when they are ready to
            sell or buy. Flow also targets your website audiences to bring back
            lost opportunities.
          </p>
        </div>
        <div className="w-full md:w-1/2 md:pr-12">
          <img
            className="mx-auto w-full mb-10 md:mb-0 md:pr-20"
            src={SuburbRelationship}
            alt=""
          />
        </div>
      </div>
    </section>

    <section className="mt-12 md:mt-0 py-10 md:py-16 px-5 mx-5 md:mx-10 rounded-xl">
      <div className="container mx-auto flex flex-col-reverse md:flex-row">
        <div className="w-full md:w-1/2 text-center md:text-left">
          <h2 className="text-2xl md:text-3xl text-center md:text-left font-bold text-gray-700 leading-none mt-4 md:mt-3">
            Dedicated Agency Branded Pages
          </h2>
          <p className="text-md md:text-xl text-center md:text-left font-light text-gray-400 max-w-md mt-4">
            You get a Branded Lead Page with your brand and your listings,
            optimised for social and to drive mobile conversions.
          </p>

          <div className="w-full flex text-left max-w-sm mt-12">
            <div className="flex inline-block w-5 h-5 rounded-full border-2 text-orange-500 border-orange-500 text-white flex-shrink-0 text-center mr-4">
              <i className="w-full self-center text-xxs fas fa-check"></i>
            </div>
            <div>
              <p className="font-medium text-gray-700 ">
                Exclusively showing your brand and listings.
              </p>
            </div>
          </div>

          <div className="w-full flex text-left max-w-sm mt-3">
            <div className="flex inline-block w-5 h-5 rounded-full border-2 text-orange-500 border-orange-500 text-white flex-shrink-0 text-center mr-4">
              <i className="w-full self-center text-xxs fas fa-check"></i>
            </div>
            <div>
              <p className="font-medium text-gray-700 ">
                Keep your competition out! Drive leads to 100% of your property
                listings.
              </p>
            </div>
          </div>

          <div className="w-full flex text-left max-w-sm mt-3">
            <div className="flex inline-block w-5 h-5 rounded-full border-2 text-orange-500 border-orange-500 text-white flex-shrink-0 text-center mr-4">
              <i className="w-full self-center text-xxs fas fa-check"></i>
            </div>
            <div>
              <p className="font-medium text-gray-700 ">
                Showcase your sold stock and track record.
              </p>
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/2">
          <img
            className="mx-auto w-full mb-10 md:mb-0 md:pl-20"
            src={AgencyPage}
            alt=""
          />
        </div>
      </div>
    </section>

    <section className="bg-gray-100 mt-12 md:mt-12 py-10 md:py-16 px-5 mx-5 md:mx-10 rounded-xl">
      <div className="container mx-auto flex flex-col-reverse md:flex-row-reverse">
        <div className="w-full md:w-1/2 text-center md:text-left">
          <h2 className="text-2xl md:text-3xl text-center md:text-left font-bold text-gray-700 leading-none mt-4 md:mt-3">
            Leads delivered for performance & deliverability
          </h2>
          <p className="text-md md:text-xl text-center md:text-left font-light text-gray-400 max-w-md mt-4">
            Listing agents are automatically assigned leads based on their
            portfolio through Email, SMS and the Flow portal. Leads are
            instantly notified of their enquiry through Email, SMS and WhatsApp
            with their listing agents and property details.
          </p>
        </div>
        <div className="w-full md:w-1/2 md:pr-12">
          <img
            className="mx-auto w-full mb-10 md:mb-0 md:pr-20"
            src={LeadsDiagram}
            alt=""
          />
        </div>
      </div>
    </section>

    <section className="mt-12 md:mt-0 py-10 md:py-16 px-5 mx-5 md:mx-10 rounded-xl">
      <div className="container mx-auto flex flex-col-reverse md:flex-row">
        <div className="w-full md:w-1/2 text-center md:text-left">
          <h2 className="text-2xl md:text-3xl text-center md:text-left font-bold text-gray-700 leading-none mt-4 md:mt-3">
            Make informed decisions in real-time
          </h2>
          <p className="text-md md:text-xl text-center md:text-left font-light text-gray-400 max-w-md mt-4">
            Understand your business better. Interpret customised data on leads,
            reach and recruitment results from one beautifully designed
            dashboard.
          </p>
        </div>
        <div className="w-full md:w-1/2">
          <img
            className="mx-auto w-full mb-10 md:mb-0 md:pl-20"
            src={LeadAnalyticsDashboard}
            alt=""
          />
        </div>
      </div>
    </section>

    <img className="w-full" src={wave} alt="" />

    <section className="container mx-auto px-10 md:px-0">
      <div className="w-full text-center">
        <h2 className="text-2xl md:text-4xl mx-auto font-bold text-gray-700 leading-none">
          Success Stories
        </h2>
        <p className="inline-block text-md md:text-xl font-light text-gray-400 max-w-2xl mx-auto mt-3">
          See how businesses similar to yours are growing with Flow, building
          trust in their brand and generating top quality buyer, seller and
          tenant leads.
        </p>
      </div>
    </section>

    <section className="bg-gray-100 mt-12 py-10 md:py-16 px-5 mx-5 md:mx-10 rounded-xl">
      <div className="container mx-auto flex flex-col md:flex-row">
        <div className="w-full md:mr-10">
          <img className="" src={CasestudySeeff} alt="" />
        </div>
        <div className="w-full">
          <h2 className="text-2xl md:text-3xl text-center md:text-left font-bold text-gray-700 leading-none mt-4 md:mt-3">
            Attracting qualified seller leads through keeping Seeff KZN brand
            Top-Of-Mind
          </h2>
          <p className="text-md md:text-xl text-center md:text-left font-light text-gray-400 max-w-md mt-4">
            Having traded for over half a century, Seeff has grown from a small,
            family-owned business to become the most established Real Estate
            brand in Southern Africa. Always looking to innovate, Seeff mandated
            Flow to test if we could generate seller leads for their 11 KZN
            offices, and prove that we can create brand recall.
          </p>
          <div className="flex mt-4">
            <div className="w-1/3">
              <div className="text-2xl font-bold text-orange-500">30%</div>
              <div className="text-sm text-gray-500">
                lift in organic traffic to Seeff nationally
              </div>
            </div>
            <div className="w-1/3">
              <div className="text-2xl font-bold text-orange-500">54</div>
              <div className="text-sm text-gray-500">seller leads</div>
            </div>
            <div className="w-1/3">
              <div className="text-2xl font-bold text-orange-500">R20m+</div>
              <div className="text-sm text-gray-500">
                worth of mandate signed
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="container mx-auto text-center">
      <Link
        to={`/case-studies`}
        className="inline-block bg-orange-200 text-orange-500 rounded-lg p-3 cursor-pointer mt-5 md:mt-20"
      >
        See more case studies <i className="fas fa-arrow-right ml-2"></i>
      </Link>
    </section>

    <section className="container mx-auto px-10 md:px-0 my-10 md:my-32">
      <div className="w-full text-center">
        <h2 className="text-2xl md:text-4xl mx-auto font-bold text-gray-700 leading-none">
          Frequently asked questions
        </h2>
        <p className="inline-block text-md md:text-xl font-light text-gray-400 max-w-2xl mx-auto mt-3">
          Real questions, from real partners{" "}
        </p>
      </div>

      <AgenciesFaq />
    </section>

    <section className="bg-gray-100 mt-12 py-10 md:py-16 px-5 mx-5 md:mx-10 rounded-xl">
      <div className="container mx-auto text-center">
        <h1 className="text-3xl md:text-5xl text-gray-700 font-bold leading-tight tracking-tight md:mx-0 animated fadeIn">
          Start using Flow to <span className="wrap">automate</span> your digital presence on Facebook, Instagram and the web
        </h1>
        <Link
          to="/book-demo"
          className="inline-block bg-orange-500 text-white rounded-lg p-3 cursor-pointer mt-5"
        >
          Book a demo <i className="fas fa-arrow-right ml-2"></i>
        </Link>
      </div>
    </section>
    <Footer />
  </Layout>
)

export default ForAgencies
