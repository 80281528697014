import React from "react"
import { Disclosure } from "@headlessui/react"

const faqs = [
  {
    question: "Can I choose which listings feature on my campaigns?",
    answer:
      "Unlike other property listing platforms, you have complete control over which listings get prioritised over others. This automatically allows you to remove unwanted listings from being promoted (such as sold stock) and be more aggressive on more strategic properties (such as newly listed properties, key suburbs, top agents or sole mandates).",
  },
  {
    question: "Will I need to design my ads? ",
    answer:
      "Flow automatically converts your Listings and profile data from your feed into advertising assets to promote your company. But, if you have the in-house capabilities to produce content in-line with your brand, then you can submit this creative to automatically be converted into a Flow ad.",
  },
  {
    question: "How do you target the right audiences?",
    answer:
      "Based on industry and user data, we know where the most appropriate buyers, sellers and tenants are across Facebook and millions of web pages. Through retargeting people who have shown interest in your brand and properties, we build your ideal buyer profile based on your stock and listing suburbs to find and convert the highest quality leads for your team to close. ",
  },
  {
    question: "I already do promoted posts, what’s the difference?",
    answer:
      "Promoted posts offer limited targeting ability and tools. FlowFuel taps into the complete Facebook ads system and creates a layer of automation specific for property businesses - which is not available directly on Facebook. FlowFuel automatically converts your entire property listings into high performing ad campaigns that convert into hot leads for your agents to close, something that requires a full marketing team. FlowFuel leverages data to find and retarget the most appropriate buyers, sellers and tenants with the most relevant properties at their best time to convert.",
  },
  {
    question: "What’s the risk if I don’t use Flow?",
    answer:
      "Failure to move your brand into the digital space will see a missed opportunity to be at the exact place where buyers and sellers are actively seeking your services. Competitors who are already in this space will see an advantage over you by speaking to your existing and prospective clients on an extremely familiar platform.",
  },
  {
    question: "How do I monitor my campaign performance?",
    answer:
      "Your campaign performance is crucial to informing greater business decisions, that’s why we provide you and your agents with a real-time reporting dashboard and lead management system that showcases your campaigns performance in a digestible and informative way. The dashboard presents all your leads, spend, agent lead volumes and so much more!",
  },
  {
    question: "How do I receive leads?",
    answer:
      "Did you know that the chances of converting a hot lead deteriorates by 40% every 5 mins? This means that it is vital that your agents receive the leads immediately after the enquiry is made. Your agents will receive their leads via SMS and Email, with a WhatsApp to start a chat, giving you the highest chance of reaching out to the applicant as fast and efficiently as possible. You can also review lead details in your Flow account, and in your CRM if you choose to integrate it with Flow.",
  },
  {
    question: "Who determines my budget? How much does it cost?",
    answer:
      "Flow does NOT work on a pay per listing model, but rather determines your budget based on your goal and volume of leads required. Our account management team is there to assist you with allocating budget according to the project outcome of the campaign’s performance. You can allocate budget according to suburbs, top agents, sole mandate, listing type or even price point.",
  },
  {
    question:
      "I’ve been doing street pole & dustbin ads for years, why do I need Flow?",
    answer:
      "There’s a new world of buyers, sellers and tenants spending over 4 hours per day on Facebook and top websites. People are not paying attention to the noise of outdoor media, and even travel less these days and won’t see it. Agencies are falling behind by no longer being top of mind for the new consumer. FlowBrand builds your agency or development’s digital presence and targets the most appropriate audience to increase recall and awareness in your operating suburbs.",
  },
  {
    question: "What results should I expect for my business from Flow?",
    answer:
      "More than 50% of sellers only talk with one agent before deciding who to work with - highlighting the importance of staying top-of-mind. Brand awareness through regular and effective advertising is vital to building trust and credibility - helping you to get in early on a seller’s and buyer’s consideration process - which is months! You will establish credibility amongst new-age buyers and high volumes of reach in highly targeted operating suburbs. You can expect people to call you, Google you, more engagement on your Facebook Page, and more quality leads with FlowFuel.",
  },
  {
    question:
      "I’m not seeing my ads when I’m on Facebook. How will I know if my ads are running and whether I have a digital presence?",
    answer:
      "As an agency owner or agent, you will have complete oversight of the campaigns spend, key performance metrics and results via your Flow Dashboard. You won’t see your ad actually running on Facebook unless you are part of the target audience you selected. Even if you are part of the target audience, you aren’t guaranteed to see your ad because Facebook may have determined that other content is more relevant to you. As long as you are seeing impressions in your Flow Dashboard, your ad is running and can be seen by people on Facebook/Instagram.",
  },
  // More questions...
]

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export default function AgenciesFaq() {
  return (
    <div className="bg-gray-50">
      <div className="container mx-auto">
        <div className="w-full divide-y-2 divide-gray-200">
          <dl className="mt-6 space-y-4 divide-y divide-gray-300">
            {faqs.map(faq => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                        <span className="font-medium text-gray-900">
                          {faq.question}
                        </span>
                        <span className="ml-6 h-7 flex items-center">
                          <i
                            className={classNames(
                              open
                                ? "fas fa-minus-circle"
                                : "fas fa-plus-circle",
                              "h-6 w-6 transform text-gray-800"
                            )}
                            aria-hidden="true"
                          ></i>
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base text-gray-500">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
