import React from "react"
import ReactDOM from "react-dom"
import Flickity from "react-flickity-component"

import "../assets/css/flickity.css"

import fritsbeyer from "../images/testimonials/fritsbeyer.png"
import greggwilson from "../images/testimonials/greggwilson.png"
import tedfrazer from "../images/testimonials/tedfrazer.png"
import ephraimzaslansky from "../images/testimonials/ephraimzaslansky.png"
import delisilekubheka from "../images/testimonials/delisilekubheka.png"

import century21 from "../images/logos/properties/century21.png"
import remax from "../images/logos/properties/remax.png"
import seeff from "../images/logos/properties/seeff.png"
import firzt from "../images/logos/properties/firzt.png"

const Testimonial = ({ testimonial, image, name, title, logo }) => {
  return (
    <section className="container mx-auto px-5 md:px-0 self-center">
      <div className="w-full p-8 md:p-16">
        <p className="font-medium tracking-tight text-gray-700 text-md md:text-xl">
          “{testimonial}”
        </p>
        <div className="flex mt-4 leading-none">
          <div className="rounded-full bg-orange-500 border-8 border-orange-200 h-24 w-24 p-2 mr-4 flex-shrink-0">
            <img className="w-16 h-16" src={image} alt="" />
          </div>
          <div className="flex flex-col md:flex-row">
            <div className="mt-1 text-left self-center mr-4">
              <p className="">{name}</p>
              <p className="text-sm text-gray-400 mt-1">{title}</p>
            </div>
            <img className="self-center h-16 mr-auto" src={logo} alt="" />
          </div>
        </div>
      </div>
    </section>
  )
}

function TestimonialsAgencies() {
  return (
    <Flickity className="w-full testimonials">
      <Testimonial
        testimonial="FlowFuel has boosted our lead volumes to beyond where they’ve ever been, before. We sold two properties in our first month as a direct result of the leads generated and delivered to our agents’ phones, by automating our listings with Flow – and there are plenty more in the pipeline. We’ve also consolidated our position as area experts in Montana and surrounds with FlowBrand - our brand recognition is through the roof."
        image={fritsbeyer}
        name="Frits Beyer"
        title="Principal: Century21 Montana"
        logo={century21}
      />

      <Testimonial
        testimonial="Being mandated with a unique property means you have to match it with a unique buyer – and that’s something we were instantly able to do by automating the listing with FlowFuel. The precision with which it allowed us to target our online marketing meant that we matched a totally unique property with the perfect buyer, in under a month. It gives us great hope with what we can do with the rest of our inventory, too."
        image={greggwilson}
        name="Gregg Wilson"
        title="Principal: Seeff Hillcrest"
        logo={seeff}
      />

      <Testimonial
        testimonial="We knew we had to be on social media, but had no idea how to do it effectively. Using the automated Flow platform is like having an entire team working solely on our digital presence – giving us instant, measurable results and complete flexibility."
        image={ephraimzaslansky}
        name="Ephraim Zaslansky"
        title="Operations Manager, Firzt Realty"
        logo={firzt}
      />

      <Testimonial
        testimonial="FLOW delivered the perfect solution for our KZN licensees, who, as a region were looking for a way to create brand awareness and also generate leads. The agile nature of the platform to create a customized campaign for each local licensee while retaining overall brand consistency was a significant advantage. Importantly, the campaign delivered impressive results for all our licensees across the region. The dynamically responsive and automated nature of the FLOW AI created significant ROI for the region and in a short time-frame."
        image={tedfrazer}
        name="Ted Frazer"
        title="Seeff Property Group National Marketing Manager"
        logo={seeff}
      />

      <Testimonial
        testimonial="Very user friendly and efficient. Social media is important because you get to reach more potential clients than when doing it the old school way."
        image={delisilekubheka}
        name="Delisile Kubheka"
        title="RE/MAX Team Member"
        logo={remax}
      />
    </Flickity>
  )
}

export default TestimonialsAgencies
